import React from 'react';
import 'components/addCourse/addCourse.css';  
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import { post } from 'aws-amplify/api';

class AddCourse extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            course: '',
            city: '',
            state: '',
            pars: '',
            totalPar: 0,
            holes: [],
            tees: [{ name: "", length: "", slope: "", rating: "" }],
            latitude: '',
            longitude: '',
            timeZone: 'America/Chicago',
        };
  
        this.handleCourseChange = this.handleCourseChange.bind(this);
        this.handleParsChange = this.handleParsChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleLengthChange = this.handleLengthChange.bind(this);
        this.handleCityChange = this.handleCityChange.bind(this);
        this.handleStateChange = this.handleStateChange.bind(this);
        this.handleLatitudeChange = this.handleLatitudeChange.bind(this);
        this.handleLongitudeChange = this.handleLongitudeChange.bind(this);
        this.handleTimeZoneChange = this.handleTimeZoneChange.bind(this);
    }

    handleNameChange(event) {
        this.setState({
            name: event.target.value
        });
    }

    handleLengthChange(event) {
        this.setState({
            length: event.target.value
        });
    }

    handleParsChange(event) { 
        var parsInput = event.target.value;

        // only auto add space if input length is greater than stored length
        // otherwise, user is deleting characters
        if (parsInput.length > this.state.pars.length) {
            parsInput += " ";
        }

        var parsIntArray = parsInput.trim().split(/[\s]/).map(function(e) { 
            return parseInt(e);
        });

        var total = 0;
        let holes = []
        parsIntArray.forEach((element, index) => {
            total += element;
            holes.push({holeNumber: index + 1, par: element})
        });

        this.setState({
            pars: parsInput,
            numHolesPlayed: parsIntArray.length,
            totalPar: total,
            holes: holes
        });
    }

    toCaps(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    async submitCourseToServer() {

        /*
        if (!(Intl.supportedValuesOf('timeZone').includes(this.state.timeZone))) {
            alert('Invalid timezone. Examples: America/New_York, America/Chicago, America/Denver, America/Los_Angeles');
            return
        }*/

        const apiName = 'shankapi';
        const path = '/uploadCourse'; 
        const options = { // OPTIONAL
            headers: { 'Content-Type': 'application/json'}, // OPTIONAL
            response: true,
            body: { 
                courseName: this.state.course,
                city: this.state.city,
                state: this.state.state,
                totalPar: this.state.totalPar,
                tees: this.state.tees,
                holes: this.state.holes,
                geocode: {
                    latitude: this.state.latitude,
                    longitude: this.state.longitude
                },
            }, 
        };
        console.log(options);
        
        post({apiName, path, options})
        .then(response => {
            if (response.status === 200){
                alert('Uploaded ' + this.state.course);
                window.location.reload();
            } else {
                console.log(response);
                alert('Error uploading course:' + response.statusText);
            }
        })
        .catch(error => {
            console.log(error.response);
        });
    }
    
    handleSubmit(event) {
        event.preventDefault();
        this.submitCourseToServer();
    }
    
    handleCourseChange(event) {
        let courseName = event.target.value;
        this.setState({
            course: courseName
        });
    }

    handleCityChange(event) {
        this.setState({
            city: event.target.value
        });
    }

    handleStateChange(event) {
        this.setState({
            state: event.target.value
        });
    }

    handleLatitudeChange(event) {
        this.setState({
            latitude: event.target.value
        });
    }

    handleLongitudeChange(event) {
        this.setState({
            longitude: event.target.value
        });
    }

    handleTimeZoneChange(event) {
        this.setState({
            timeZone: event.target.value
        });
    }

    addTee() {
        this.setState(({
            tees: [...this.state.tees, { name: "", length: "", slope: "", rating: "" }]
        }))
    }

    handleTeeChange(i, e) {
        let tees = this.state.tees;
        tees[i][e.target.name] = e.target.value;
        this.setState({ tees });
    }

    render() {
        return (
            <div>
            <Card>

                <Card.Body>
                <Form onSubmit={this.handleSubmit}>
                    <Form.Row>
                        <Form.Group as={Col} lg="3" md="4" xs="12" controlId="formCourse">
                            <Form.Label>Course Name</Form.Label>
                            <Form.Control required type="text" value={this.state.course} onChange={this.handleCourseChange}/>
                        </Form.Group>

                        <Form.Group as={Col} lg="3" md="3" xs="6">
                            <Form.Label>City</Form.Label>
                            <Form.Control required type="text" onChange={this.handleCityChange} />
                        </Form.Group>

                        <Form.Group as={Col} lg="2" md="1" xs="6">
                            <Form.Label>State</Form.Label>
                            <Form.Control maxLength="2" required type="text" onChange={this.handleStateChange} />
                        </Form.Group>

                        <Form.Group as={Col} lg="2" md="2" xs="6" controlId="formCourse">
                            <Form.Label>Latitude</Form.Label>
                            <Form.Control type="number" value={this.state.latitude} onChange={this.handleLatitudeChange}/>
                        </Form.Group>

                        <Form.Group as={Col} lg="2" md="2" xs="6">
                            <Form.Label>Longitude</Form.Label>
                            <Form.Control type="number" value={this.state.longitude} onChange={this.handleLongitudeChange} />
                        </Form.Group>
                    </Form.Row>

                    {this.state.tees.map((element, index) => (
                        <Form.Row>

                            <Form.Group as={Col} lg="3" md="3" xs="6" controlId="formCourse">
                                <Form.Label>Tee Name</Form.Label>
                                <Form.Control required type="text" name="name" value={element.name || ""} onChange={e => this.handleTeeChange(index, e)}/>
                                <Form.Text className="text-muted">
                                    blue, red, member, back, championship etc
                                </Form.Text>
                            </Form.Group>

                            <Form.Group as={Col} lg="3" md="3" xs="6">
                                <Form.Label>Length</Form.Label>
                                <Form.Control required type="number" name="length" value={element.length || ""}  onChange={e => this.handleTeeChange(index, e)} />
                            </Form.Group>

                            <Form.Group as={Col} lg="3" md="3" xs="6">
                                <Form.Label>Rating</Form.Label>
                                <Form.Control type="number" name="rating" value={element.rating || ""} onChange={e => this.handleTeeChange(index, e)} />
                            </Form.Group>

                            <Form.Group as={Col} lg="3" md="3" xs="6">
                                <Form.Label>Slope</Form.Label>
                                <Form.Control type="number" name="slope" value={element.slope || ""} onChange={e => this.handleTeeChange(index, e)} />
                            </Form.Group>

                        </Form.Row>
                    ))}
                    <Form.Row>
                        <Form.Group as={Col} lg="4" md="8" xs="12" controlId="formCourse">
                            <Button variant="secondary" onClick={() => this.addTee()}>
                                Add tee
                            </Button>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} lg="6" md="6" xs="12" controlId="formScorecard">
                            <Form.Label>Pars</Form.Label>
                            <Form.Control required type="tel" value={this.state.pars} onChange={this.handleParsChange} />
                            <Form.Text className="text-muted">
                            Enter the pars starting with hole 1
                            </Form.Text>
                        </Form.Group>
                        <Form.Group as={Col} lg="3" md="3" xs="6" controlId="formHoles">
                            <Form.Label>Holes</Form.Label>
                            <Form.Control type="text" value={this.state.numHolesPlayed} disabled/>
                        </Form.Group>
                        <Form.Group as={Col} lg="3" md="3" xs="6" controlId="formScore">
                            <Form.Label>Total Par</Form.Label>
                            <Form.Control type="text" value={this.state.totalPar} disabled/>
                        </Form.Group>
                    </Form.Row>                 
                    <Button variant="primary" type="submit">
                        Submit
                    </Button>
                </Form>
                </Card.Body>
            </Card>
            </div>
        );
    }
}

export default AddCourse;