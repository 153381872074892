import React from "react";
import iosInstallIcon from 'images/appStoreBlack.svg'
import homeSS from 'images/homeSS.png'
import 'components/about/about.css';

class About extends React.Component {

    render() {

        return (
            <div className="AboutContent">
                <h4 class="about-header">NOW PRESENTING</h4>
                <h3>Shank Scores</h3>
                <p>Never shank alone - join your buddies and improve your game together with Shank Scores for iOS!</p>

                <a title="Go to Shank Scores on the iOS app store" href="https://apps.apple.com/us/app/shank-scores/id1608112527">
                    <img alt="Icon for installing on the app store" src={iosInstallIcon} />
                </a>

                <img alt="Shank Scores screenshot" class="screenshot" src={homeSS} />
                
            </div>
        );
    }
}

export default About;
