import React from 'react';
import 'components/addCourse/addCourse.css';  
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import { post, get } from 'aws-amplify/api';
import Autocomplete from 'react-autocomplete';
import ReactDiffViewer from 'react-diff-viewer';


class EditCourse extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            courseData: '',
            originalCourseData: '',
            course: '',
            courseOptions: [],
        };
  
        this.handleCourseDataChange = this.handleCourseDataChange.bind(this);
        this.handleCourseChange = this.handleCourseChange.bind(this);
        this.submitCourseToServer = this.submitCourseToServer.bind(this);
    }

    handleCourseDataChange(event) {
        this.setState({
            courseData: event.target.value
        });
    }

    handleCourseChange(event) {
        let courseName = event.target.value;
        this.setState({
            course: courseName
        });
        if (courseName.length > 0) {
            this.searchCourses(courseName)
        }
        else {
            this.setState({
                courseOptions: []
            });
        }
    }

    searchCourses(course) {
        const apiName = 'shankapi';
        const path = `/searchCourses/${course}`; 
        const options = { // OPTIONAL
            headers: { 'Content-Type': 'application/json'}, // OPTIONAL
            response: false
        };

        get({apiName, path, options}).response
            .then(response => {
                response.body.json().then(courseOptions => {
                    this.setState({
                        courseOptions: courseOptions.map(x => x._source)
                    });  
                })             
            })
    }

    getCourse(courseName, city, state) {
        const apiName = 'shankapi';
        const path = `/course/${courseName}/${city}/${state}`; 
        const options = { // OPTIONAL
            headers: { 'Content-Type': 'application/json'}, // OPTIONAL
            response: false // OPTIONAL (return the entire Axios response object instead of only response.data)
        };

        get({apiName, path, options}).response
        .then(response => {
            response.body.json().then(courseData => {
                console.log(courseData.metadata);
                this.setState({
                    courseData: JSON.stringify(courseData.metadata, null, 2),
                    originalCourseData: JSON.stringify(courseData.metadata, null, 2)
                });  
            })  
        });
    }

    submitCourseToServer() {
        console.log("submitting to server")

        let jsonCourseData;
        try {
            jsonCourseData = JSON.parse(this.state.courseData)
        } catch (error) {
            alert("Error parsing JSON");
            return;
        }

        const apiName = 'shankapi';
        const path = '/uploadCourse'; 
        const options = { // OPTIONAL
            headers: { 'Content-Type': 'application/json'}, // OPTIONAL
            response: true,
            body: { 
                rawCourseData: jsonCourseData
            }, 
        };
        console.log(options);
        
        post({apiName, path, options}).response
        .then(response => {
            if (response.statusCode === 200){
                alert('Uploaded ' + this.state.course);
                window.location.reload();
            } else {
                console.log(response);
                alert('Error uploading course:' + response.statusText);
            }
        })
        .catch(error => {
            console.log(error.response);
        });
    }

    render() {
        return (
            <div>
                <Card>
                    <Card.Body>
                        <Form>

                            <Form.Row>
                                <Form.Group as={Col} lg="4" md="9" xs="12" controlId="formCourse">
                                    <Form.Label>Course</Form.Label>
                                    
                                    <Autocomplete
                                        inputProps={{ id: 'course-autocomplete', className: "form-control" }}
                                        wrapperStyle={{ position: 'relative'}}
                                        value={this.state.course}
                                        items={this.state.courseOptions}
                                        getItemValue={(item) => item.course}
                                        onSelect={(course, item) => {
                                            this.setState({
                                                course: course,
                                                courseOptions: [ item ]
                                            });
                                            this.getCourse(item.course, item.city, item.state);
                                        }}
                                        onChange={this.handleCourseChange}
                                        renderMenu={children => (
                                            <div className="menu">
                                                {children}
                                            </div>
                                        )}
                                        renderItem={(item, isHighlighted) => (
                                            <div className="auto-container" style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                                                <div className="auto-course">{item.course}</div>
                                                <div className="auto-metadata">{item.city}, {item.state}</div>
                                            </div>
                                        )}
                                    />

                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col} lg="12" md="12" xs="12" controlId="formCourse">
                                    <Form.Label>Course Data</Form.Label>
                                    <Form.Control required as="textarea" rows={15} value={this.state.courseData} onChange={this.handleCourseDataChange}/>
                                </Form.Group>
                            </Form.Row>

                            <ReactDiffViewer oldValue={this.state.originalCourseData} newValue={this.state.courseData} splitView={true} />
                                            
                            <Button variant="primary" onClick={this.submitCourseToServer}>
                                Submit
                            </Button>
                        </Form>
                    </Card.Body>
                </Card>
            </div>
        );
    }
}

export default EditCourse;