import React from 'react';
import 'components/app/App.css';
import 'custom.scss';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Login from 'components/login/login';
import About from 'components/about/about';
import Support from 'components/support/support';
import { BrowserRouter as Router, Link, Route, Switch } from "react-router-dom";
import { signOut } from 'aws-amplify/auth';
import useIsLoggedIn from 'helpers/useIsLoggedIn';
import logo from 'images/shanktext.png'
import AddCourse from 'components/addCourse/addCourse';
import EditCourse from 'components/editCourse/editCourse';

function signout(eventKey, event) {
  signOut();
  localStorage.removeItem('username');
  window.location.reload();
}

function App() {
  const isLoggedIn = useIsLoggedIn();

  return (
    <div className="Home">
      <Router>
          <div className="header">
            <Navbar bg="light" expand="lg">
              <Nav.Link as={Link} to="/about">
                <img alt="Shank logo" class="logo" src={logo} />
              </Nav.Link>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                </Nav>
                <Nav className="justify-content-end" onSelect={signout}>
                  {!isLoggedIn && <Nav.Link as={Link} to="/login">Sign in</Nav.Link>}
                  {isLoggedIn && <Nav.Link eventKey="signout">Sign out</Nav.Link>}
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </div>
          <Container>       
            <Switch>
              <Route exact path="/">
                <About />
              </Route>
              <Route path="/index.html">
                <About />
              </Route>
              <Route path="/about">
                <About />
              </Route>
              <Route path="/addCourse">
                <AddCourse />
              </Route>
              <Route path="/editCourse">
                <EditCourse />
              </Route>
              <Route path="/app">
                <About />
              </Route>
              <Route path="/support">
                <Support />
              </Route>
              <Route path="/login" component={Login} />
            </Switch>
          </Container>
          <div class="footer">
            <div>
              © 2024 Shank LLC | <a title="Go to privacy policy" href="/privacy">Privacy policy</a> | <a title="Go to terms of use" href="/terms">Terms of use</a> 
            </div>
          </div>
        </Router>
    </div>
  );
}

export default App;
