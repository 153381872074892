import React from "react";
import 'components/about/about.css';

class Support extends React.Component {

    render() {

        return (
            <div className="AboutContent">
                <p>Please contact <a title="Mail to support@shankscores.com" href="mailto:support@shankscores.com">support@shankscores.com</a> for all support inquiries</p>        
            </div>
        );
    }
}

export default Support;
